<template>
    <div class="new-sale-box">
        <div class="title">
            {{merakez.name}}
        </div>
        <div class="input-line">
            <p class="input-label">תווית המבצע</p>
            <span class="input-note">הכיתוב אשר מופיע באלבום על הקובייה של הפריט.</span>
            <el-input placeholder="תווית המבצע" v-model="new_sale.label" :input-style="input_options"/>
        </div>
        <div class="input-line">
            <p class="input-label">תיאור המבצע</p>
            <span class="input-note">התיאור יופיע לאחר לחיצה על הפריט</span>
            <el-input placeholder="תיאור המבצע" v-model="new_sale.description" :input-style="input_options"/>
        </div>
        <div class="input-line">
            <p class="input-label">פריטים במבצע</p>
            <span class="input-note">הכנס מקטים מופרדים בפסיק (,)</span>
            <el-input placeholder="מקטים" v-model="new_sale.makats" :input-style="input_options"/>
            <el-button type="primary" style="margin-top: 5px;" @click="add_makats_to_sale">הוסף מקטים למבצע</el-button>
            <template v-for="product in new_sale.products" :key="product.makat">
                <div class="makat-line">
                    <p style="margin-left: 10px;">{{product.makat}}</p>
                    <p>{{product.name}}</p>
                    <p>({{product.boxSize}} יח' באריזה)</p>
                </div>
            </template>
        </div>
        <div class="input-line" style="margin-top: 15px;">
            <p class="input-label">מדרגות מבצע</p>
            <span class="input-note">לדוגמה: קנה 20 יח וקבל מחיר של X ש"ח ליח'</span>
            <div class="step-line">
                <p style="width: 5%; text-align:center; color:white;">1</p>
                <el-input placeholder="תיאור מדרגה" v-model="new_sale.step_1.description" :input-style="input_options" style="width: 50%;"/>
                <el-input placeholder="כמות יחידות" v-model="new_sale.step_1.amount" :input-style="input_options" style="width: 22%;"/>
                <el-input placeholder="מחיר לפני לפני" v-model="new_sale.step_1.price" :input-style="input_options" style="width: 22%;"/>
            </div>
            <div class="step-line">
                <p style="width: 5%; text-align:center; color:white;">2</p>
                <el-input placeholder="תיאור מדרגה" v-model="new_sale.step_2.description" :input-style="input_options" style="width: 50%;"/>
                <el-input placeholder="כמות יחידות" v-model="new_sale.step_2.amount" :input-style="input_options" style="width: 22%;"/>
                <el-input placeholder="מחיר לפני לפני" v-model="new_sale.step_2.price" :input-style="input_options" style="width: 22%;"/>
            </div>
            <div class="step-line">
                <p style="width: 5%; text-align:center; color:white;">3</p>
                <el-input placeholder="תיאור מדרגה" v-model="new_sale.step_3.description" :input-style="input_options" style="width: 50%;"/>
                <el-input placeholder="כמות יחידות" v-model="new_sale.step_3.amount" :input-style="input_options" style="width: 22%;"/>
                <el-input placeholder="מחיר לפני לפני" v-model="new_sale.step_3.price" :input-style="input_options" style="width: 22%;"/>
            </div>
            <div class="step-line">
                <p style="width: 5%; text-align:center; color:white;">4</p>
                <el-input placeholder="תיאור מדרגה" v-model="new_sale.step_4.description" :input-style="input_options" style="width: 50%;"/>
                <el-input placeholder="כמות יחידות" v-model="new_sale.step_4.amount" :input-style="input_options" style="width: 22%;"/>
                <el-input placeholder="מחיר לפני לפני" v-model="new_sale.step_4.price" :input-style="input_options" style="width: 22%;"/>
            </div>
            <div class="step-line">
                <p style="width: 5%; text-align:center; color:white;">5</p>
                <el-input placeholder="תיאור מדרגה" v-model="new_sale.step_5.description" :input-style="input_options" style="width: 50%;"/>
                <el-input placeholder="כמות יחידות" v-model="new_sale.step_5.amount" :input-style="input_options" style="width: 22%;"/>
                <el-input placeholder="מחיר לפני לפני" v-model="new_sale.step_5.price" :input-style="input_options" style="width: 22%;"/>
            </div>
            <p style="color:var(--danger); width:100%; text-align:center;">מחירים לפני מעמ ולפני פיקדון!</p>
        </div>
        <div class="input-line">
            <p class="input-label">תאריכי המבצע</p>
            <input type="date" name="" id="" v-model="new_sale.start_date" :style="input_options" style="font-size: 20px;">
            <input type="date" name="" id="" v-model="new_sale.end_date" :style="input_options" style="font-size: 20px;">
        </div>
        <el-button v-if="!is_duplicate" type="success" style="margin-top: 15px; width:100%;" @click="finilize_sale">סיים</el-button>
        <el-button v-if="is_duplicate" type="danger" style="margin-top: 15px; width:100%;" @click="duplicate_sale">שכפל</el-button>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Swal from 'sweetalert2'
import { projectFirestore } from '../../../firebase/config'
import { slide_pop_successs,slide_pop_error } from '../../../Methods/Msgs'
import { onMounted } from '@vue/runtime-core'
export default {
props: ["merakez", "sale","is_duplicate"],
emits: ["close"],
setup(props, {emit}){
    const new_sale = ref({
        label: '',
        description: '',
        makats: '',
        makats_arr: [],
        merakez: props.merakez.makat,
        approved: false,
        products: [],
        step_1: {
            amount: '',
            description: '',
            price: ''
        },
        step_2: {
            amount: '',
            description: '',
            price: ''
        },
        step_3: {
            amount: '',
            description: '',
            price: ''
        },
        step_4: {
            amount: '',
            description: '',
            price: ''
        },
        step_5: {
            amount: '',
            description: '',
            price: ''
        },

        uid: null
    })

    const input_options = {
        'background': 'var(--main)',
        'color': 'white',
        'flex-shrink': '0',
        'margin-top': '5px',
        'border': 'none'
    }

    const add_makats_to_sale = async () => {
        if(!new_sale.value.makats){
            Swal.fire({
                icon: 'error',
                title: 'רשום מקטים תחילה'
            })
        }else{
            let makats = new_sale.value.makats.trim().split(',')
            new_sale.value.products = [];
            new_sale.value.makats_arr = [];
            for(let i = 0; i< makats.length; i++){
                let _makat = makats[i].trim()
                if(_makat){
                    const product = await projectFirestore.collection('Products').doc(_makat).get()
                    if(!product.exists){
                        Swal.fire({
                            icon: 'error',
                            title: 'שגיאת מקט',
                            text: `מקט ${_makat} איננו נמצע במערכת`
                        })
                        new_sale.value.approved = false;
                        return
                    }else{
                        new_sale.value.products.push({
                            makat: product.data().makat,
                            name: product.data().name,
                            boxSize: product.data().boxSize
                        })
                        new_sale.value.makats_arr.push(product.data().makat)
                    }
                }
            }
            new_sale.value.approved = true
        }
    }

    const show_error = (title, msg) => {
        Swal.fire({
            icon: 'error',
            title: title,
            text: msg
        })
    }
    const finilize_sale = async () => {
        if(!new_sale.value.label){
            show_error('תווית מבצע', 'אנא מלא תווית למבצע')
            return
        }
        if(!new_sale.value.description){
            show_error('תיאור מבצע', 'אנא מלא תיאור למבצע')
            return
        }
        if(new_sale.value.products.length == 0){
            show_error('פריטים במבצע', 'אנא מלא פריטים למבצע')
            return
        }
        if(!new_sale.value.approved){
            show_error('מקטים', 'קיים מקט/ים אשר לא אושרו, אנא בדוק את שדה המקטים ולחץ הוסף מקטים למבצע.')
            return
        }
        if(!new_sale.value.start_date){
            show_error('תאריך מבצע', 'אנא מלא תאריך התחלה למבצע')
            return
        }
        if(!new_sale.value.end_date){
            show_error('תאריך מבצע', 'אנא מלא תאריך סיום למבצע')
            return
        }
        new_sale.value.date_start_date = new Date(new_sale.value.start_date)
        new_sale.value.date_end_date = new Date(new_sale.value.end_date)
        
        if(!props.sale){
            const doc = projectFirestore.collection('Sales').doc()
            new_sale.value.uid = doc.id
            delete new_sale.value.makats;
            await doc.set(new_sale.value)
            slide_pop_successs('המבצע נוסף בהצלחה!')
            emit('close', new_sale.value);
        }else{
            await projectFirestore.collection('Sales').doc(props.sale.uid).set(new_sale.value)
            slide_pop_successs('המבצע עודכן בהצלחה!')
            emit('close', new_sale.value);
        }

    }

    const duplicate_sale = async()=>{
        if(!new_sale.value.label){
            show_error('תווית מבצע', 'אנא מלא תווית למבצע')
            return
        }
        if(!new_sale.value.description){
            show_error('תיאור מבצע', 'אנא מלא תיאור למבצע')
            return
        }
        if(new_sale.value.products.length == 0){
            show_error('פריטים במבצע', 'אנא מלא פריטים למבצע')
            return
        }
        if(!new_sale.value.approved){
            show_error('מקטים', 'קיים מקט/ים אשר לא אושרו, אנא בדוק את שדה המקטים ולחץ הוסף מקטים למבצע.')
            return
        }
        if(!new_sale.value.start_date){
            show_error('תאריך מבצע', 'אנא מלא תאריך התחלה למבצע')
            return
        }
        if(!new_sale.value.end_date){
            show_error('תאריך מבצע', 'אנא מלא תאריך סיום למבצע')
            return
        }
       

        if(props.sale && props.is_duplicate){
            const clone_sale = JSON.parse(JSON.stringify(new_sale.value))
            clone_sale.date_start_date = new Date(new_sale.value.start_date)
            clone_sale.date_end_date = new Date(new_sale.value.end_date)
           
            const doc = projectFirestore.collection('Sales').doc()
            clone_sale.uid = doc.id

            await doc.set(clone_sale)

            emit('close', clone_sale);
            slide_pop_successs('המבצע שוכפל בהצלחה!')
        }else{
            slide_pop_error('אירעה שגיאה, אינך יכול לשכפל פריט זה!')
        }

    }

    onMounted(() => {
        if(props.sale){
            new_sale.value = props.sale
        }
    })
    return{
        duplicate_sale,
        add_makats_to_sale,
        finilize_sale,
        input_options,
        new_sale,
    }
}
}
</script>

<style scoped>
.new-sale-box{
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    background: var(--secondary);
}
.title{
    width: 100%;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background: var(--success);
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.637);
    white-space: nowrap;
    flex-shrink: 0;
}
.input-line{
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: 0 3px 0 3px;
    margin-bottom: 5px;
}
.input-label{
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: var(--yellow);
}
.input-note{
    width: 100%;
    color: var(--light);
    text-align: center;
}
.makat-line{
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: whitesmoke;
}
.step-line{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
</style>